import axios from "axios";

const API_APPLICATION_DATA = process.env.REACT_APP_API_APPLICATION_DATA || "";
const API_KEY = process.env.REACT_APP_API_KEY || "";
const API_PRODUCTS_DATA = process.env.REACT_APP_API_PRODUCTS_DATA || "";
const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID || "";

let _localeId = "270";
let _locale = "en-en";

export function loadData(locale: { locale: string; localeID: string }) {
  if(locale) {
    _locale = locale.locale;
    _localeId = locale.localeID;
  }
  return axios.get(`${API_PRODUCTS_DATA + _locale}?ignoreComponents=true`, {
    headers: {
      "X-Header-ApiKey": API_KEY,
      "X-Header-AppId": APPLICATION_ID,
    },
  });
}

export function loadApplicationData(locale: {locale: string; localeID: string}) {
  if(locale) {
    _locale = locale.locale;
    _localeId = locale.localeID;
  }
  return axios.get(API_APPLICATION_DATA +  _locale, {
    headers: {
      "X-Header-ApiKey": API_KEY,
      "X-Header-AppId": APPLICATION_ID,
    },
  });
}

export function getLocales() {
  return axios.get("https://volvo-trucks-01-one-application.onecx.cloud/api/v1/locales", {
    headers: {
      "X-Header-ApiKey": API_KEY,
      "X-Header-AppId": APPLICATION_ID,
    },
  });
}
