import {Props} from "./types";
import {useContext, useEffect} from "react";
import {DataContainerContext} from "../../common/context/DataContainerProvider";
import {ConfiguratorOptions} from "../../enum/enums";
import {VariantItemSwiper} from "./VariantItemSwiper";
import {VariantItemSwiperColors} from "./VariantItemSwiperColors";
import DataContainer from "../../services/DataContainer";
import { useParams } from "react-router-dom";

export const VariantItem = (props : Props) => {

  const {activeComponent} = useContext(DataContainerContext);
  const modelName = DataContainer.getActualModelName();

  const handleNoSelection = (variations: any, action: string)=>{
    if(action === "selection_change"){
      const allComponents = DataContainer.getAllComponents();
      const selectedVariations = DataContainer.findVariationsByIds(variations);
      
      const ignoreComponentKeys = ["Engine_badges", "Engine_type_badges", "Transmission_badges", "Globetrotter_signs"];
      allComponents.filter((c:any)=>!ignoreComponentKeys.includes(c.name)).forEach((component:any) => {
        let cc = selectedVariations.find((v:any) => v.component_id === component.id);
        
        const currentSegment = localStorage.getItem("segment");

        if(cc && !allowedSelectionForComponent(currentSegment, component).find((v:any)=>v.id===cc.id)){
          cc = null;
        }

        if(!cc){
          const nextSelection = filterComponentVariations(component);
          if(nextSelection){
            DataContainer.toggleVariationSelection(nextSelection.id, "add", true);
          }
        }
      });
    }
  };

  const allowedSelectionForComponent = (currentSegment:any, component:any) => {
    
    const sectionKey =
      `${modelName}_${currentSegment}_DISABLED_OPTIONS`.toLocaleLowerCase();
    const variationsToSkip = DataContainer
      .sectionKey("Segment.Settings", sectionKey)
      .split(",");
    const trimmedVariationsToSkip = variationsToSkip.map((s: string) => s.trim());
    
    const filteredVariations = component.available_variations.filter((variation: any) => {
      if(trimmedVariationsToSkip.includes(variation.original_key)){
        return false;
      } 
      else if(variation.dependencies?.length > 0){
        const chosenVariationBasedOnDependencyOfParentVariation = variation.dependencies
          .find((dependency: any) => DataContainer.isVariationSelected(dependency.depends_on_variation_id));
        return chosenVariationBasedOnDependencyOfParentVariation ? true : false;
      } 
      return true;
    });

    return filteredVariations;
  };

  const filterComponentVariations = (component: any) => {
    const currentSegment = localStorage.getItem("segment");

    const filteredVariations = allowedSelectionForComponent(currentSegment, component);

    if(!filteredVariations){
      return null;
    }

    const defVar = filteredVariations.find((v:any)=>v.is_default === true);

    return defVar ? defVar : filteredVariations[0];
  };

  useEffect(()=>{
    DataContainer.registerSelectionHook(handleNoSelection);
  }, []);

  useEffect(()=>{
    if(DataContainer.mLoadedData != null 
      && DataContainer.mSelectedVariations.length < DataContainer.getAllComponents().length){
      handleNoSelection(DataContainer.mSelectedVariations, "selection_change");
    }
  }, [DataContainer.mLoadedData]);

  return activeComponent.name === ConfiguratorOptions.COLORS ? <VariantItemSwiperColors {...props} /> : <VariantItemSwiper {...props} />;
};
