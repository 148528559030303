import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { Header } from "../../components/Header/Header";
import Slider from "../../components/Slider/SliderROD";
// import { Slider } from "../../components/Slider/Slider";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { useContext, useEffect, useState } from "react";
import { VariantItem } from "../../components/Variant-Item/VariantItem";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import dataContainer from "../../services/DataContainer";
import { ActiveComponent, ActiveComponentGroups } from "./configurator.model";
import { Loader } from "../../components/Loader/Loader";
import { AdobeCampaignService } from "../../services/adobe/AdobeCampaign";
import { AdobeEventType } from "../../enum/adobe/adobeEventType";
import { Buffer } from "buffer";
import {MainButtons, HiddenOptions, MainSettings} from "../../enum/enums";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { buildConfiguratorFromURL } from "../../services/augmented-reality/augmentedReality";
import mergeImages from "../../utils/mergeImages";
import classNames from "classnames";
import useWindowHeight from "../../utils/useWindowHeight";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../components/Slider/swiper.css";
import PanoramicViewROD from "../../components/PanoramicView360/panoramicViewROD";

export const Configurator = () => {
  const {
    data,
    currentLocale,
    activeComponent,
    setActiveComponent,
    activeComponentGroups,
    setActiveComponentGroups,
    stateOfComponent,
    dispatchStateOfComponent,
    componentArrayLength,
    setModelName,
    searchParams
  } = useContext(DataContainerContext);
  const { modelName } = useParams();
  const [viewPoints, setViewPoints] = useState<any>([]);
  const [viewPointId, setViewPointID] = useState<number>();
  // const [threeSixtyImageUrl, setThreeSixtyImageUrl] = useState("");  //not necessary on ROD
  const navigate = useNavigate();
  const component_groups = dataContainer.getComponentGroups()?.filter((item: any) => item.name !== HiddenOptions.HIDDEN_OPTIONS);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [configuratorAllImages, setConfiguratorAllImages] = useState([]);
  const [configuratorImage, setConfiguratorImage] = useState("");
  const [renderImage, setRenderImage] = useState(false);
  const adobeService = new AdobeCampaignService();
  const prevActiveComponentGroupsName = adobeService.usePrevious(activeComponentGroups.name);
  const prevActiveComponentName = adobeService.usePrevious(activeComponent.name);
  const [imageView360, setImageView360] = useState(true);
  const height = useWindowHeight();

  useEffect(() => {
    const app = document.getElementById(process.env.REACT_APP_ID as string);

    if (!app) {
      return;
    }

    // Colors div is causing scroll, this will prevent any default scrolling for the whole body.
    app.classList.add("!overflow-y-hidden");

    return () => {
      app.classList.remove("!overflow-y-hidden");
    };
  }, []);

  useEffect(() => {
    setModelName(modelName);
    if(dataContainer && dataContainer.mAttributes.length === 0){
      //automatic attributes selection based on market
      dataContainer.mAttributesDependingOnMarket.forEach((attr)=>{
        const productAttribute = dataContainer.getProductAttributes().find((attribute)=>attribute.name===attr);
        if(productAttribute){
          const defaultSelectionValue = dataContainer.sectionKey("Market.Settings", attr.toLowerCase());
          const attribute = dataContainer.findAttributeValueObjectByAttributeIdAndValue(productAttribute.id, defaultSelectionValue);

          if(attribute){
            dataContainer.markAttributeAsSelected({...attribute, market_based: true});
          }
        }
      });
    }
    dataContainer.mLoadedData && setIsLoadingProduct(false);    
    data?.map((item: any) => {
      if (item.product_info.description === modelName) {        
        dataContainer.loadData(() => {
          dataContainer.instance().mLoadedData;
          setActiveComponent({name: "", group_id: 0, index: 0 });
          setActiveComponentGroups({ name: "", id: 0, index: 0 });
          setIsLoadingProduct(false);
        },
        currentLocale,
        item.id,
        item.cached_url
        );
      }
    });
    if(!viewPoints?.length) setViewPoints(dataContainer.getProductViewPoints());
  }, [!data?.length,currentLocale, dataContainer.mLoadedData]);

  useEffect(() => {
    if (dataContainer.mLoadedData && searchParams) {
      buildConfiguratorFromURL(searchParams, component_groups);
    }
  }, [dataContainer.mLoadedData, searchParams]);  

  function componentGroupsClickEvent(componentGroups: ActiveComponentGroups) {
    setActiveComponentGroups(componentGroups);
    setActiveComponent({
      name: component_groups[componentGroups.index]?.components[0]?.name,
      group_id: component_groups[componentGroups.index]?.components[0]?.id
    });
    dispatchStateOfComponent({
      type: "resetComponent",
      payload: 0
    });
    dataContainer.getSelectedVariationsForPreviousComponentGroup(activeComponentGroups);
    adobeService.setCategory(prevActiveComponentGroupsName);
    adobeService.sendAnalytics(AdobeEventType.PAGE_ENTERED, { category: prevActiveComponentGroupsName });
  }

  function menuListClickEvent(component: ActiveComponent) {
    setActiveComponent(component);
    dispatchStateOfComponent({
      type: "setComponentCount",
      payload: component.index
    });
  }
  useEffect(() => {
    if (dataContainer.mLoadedData) {
      if(!viewPoints?.length) setViewPoints(dataContainer.getProductViewPoints());
    }
  }, [dataContainer.mLoadedData, viewPoints]);

  useEffect(() => {
    if (activeComponent.index > componentArrayLength) {
      changeActiveComponetGroups("next");
    } else if (stateOfComponent.countComponent < 0) {
      changeActiveComponetGroups("back");
    } else {
      setPayloadOfStateComponent();
      dataContainer.mLoadedData && getActivatedComponent();
    }
  }, [stateOfComponent.countComponent]);

  useEffect(() => {
    setPayloadOfStateComponent();
    dataContainer.mLoadedData && getActivatedComponent();
  }, [componentArrayLength]);


  function setPayloadOfStateComponent() {
    stateOfComponent.countComponent < 0 &&
      dispatchStateOfComponent({
        type: "setComponentCount",
        payload: componentArrayLength
      });
    stateOfComponent.countComponent > componentArrayLength &&
      dispatchStateOfComponent({
        type: "resetComponent",
        payload: 0
      });
  }

  function getActivatedComponent() {
    const component = component_groups[activeComponentGroups.index]?.components[stateOfComponent?.countComponent];
    setActiveComponent({
      name: component?.name,
      group_id: component?.id,
      index: stateOfComponent?.countComponent
    });
    adobeService.setSubCategory(prevActiveComponentName);
  }

  function changeActiveComponetGroups(type: string) {
    dataContainer.getSelectedVariationsForPreviousComponentGroup(activeComponentGroups);
    const componentGroup = component_groups[type === "next" ?
      activeComponentGroups.index + 1 :
      activeComponentGroups.index - 1 ];
    setActiveComponentGroups({
      name: componentGroup?.name,
      id: componentGroup?.id,
      index: type === "next" ?
        activeComponentGroups.index + 1 :
        activeComponentGroups.index - 1
    });
    getActivatedComponent();
    adobeService.setCategory(prevActiveComponentGroupsName);
    adobeService.sendAnalytics(AdobeEventType.PAGE_ENTERED, {category: prevActiveComponentGroupsName});
  }

  function navigateThroughComponents(
    canChangeComponentGrouop: boolean,
    checkIfItHasNextOrPrevComponentGroups: boolean,
    type: string) {
    if(canChangeComponentGrouop) {
      if (checkIfItHasNextOrPrevComponentGroups) {
        activeComponentGroups.index !==0 &&
        navigate(`/${currentLocale.locale}/summary/${modelName}/${localStorage.getItem("currentSelections")}`);
        dataContainer.getSelectedVariationsForPreviousComponentGroup(activeComponentGroups);
        return;
      } else {
        dispatchStateOfComponent({ type: type, payload: 1 });
      }
    }
  }
  useEffect(() => {
    const populatedViewpoints = viewPoints?.map((view: any, index : number) => {
      return { ...view, image: Buffer.from(configuratorAllImages[index], "binary").toString("base64") };
    });
    setViewPoints(populatedViewpoints);
  },[configuratorAllImages]);

  useEffect(() => {
    const populateCurrentViewPoint = viewPoints?.map((view: any) => {
      if(view.id === viewPointId) {
        return { ...view, image: configuratorImage };
      }
      return view;
    });
    setViewPoints(populateCurrentViewPoint);
  }, [configuratorImage]);
  
  useEffect(() => {
    const componentGroups = ["Engine_and_transmission", "Exterior", "Chassis", "Interior"];
    if(componentGroups.includes(activeComponentGroups.name)) {
      dataContainer.sendDataToAdobeDataLayer(`truckbuilder_${activeComponentGroups.name.toLowerCase()}_loaded`);
    }
  }, [activeComponentGroups]);

  const trimLevelsViewPoint = dataContainer.getAllProductViewPoints()?.find((n: any)=> n.name === "interior_driverView");
  const seatsViewPoint = dataContainer.getAllProductViewPoints()?.find((n: any)=> n.name === "interior_bedView");  
  const defaultViewPoint = dataContainer.getProductViewPoints()?.find((vp: any) => vp.name === "010");

  if (!isLoadingProduct && activeComponentGroups.name !== "Interior") {
    const arr = ["interior_driverView", "interior_bedView", "interior_panoramic"];
    if (arr.includes(dataContainer.getCurrentPointOfViewName())) {
      dataContainer.setCurrentViewPoint(defaultViewPoint);
    }
  } else if (activeComponentGroups.name === "Interior") {
    if(activeComponent.name === "Int_trim_levels") {
      dataContainer.setCurrentViewPoint(trimLevelsViewPoint);
    }
    else if(activeComponent.name === "Seats") {
      dataContainer.setCurrentViewPoint(seatsViewPoint);
    }
  }

  return (
    <div className="flex flex-col h-full w-full">
      {isLoadingProduct && <Loader text="Loading Product Data..."/>}
      <Header
        listSidebar={component_groups}
        onSidebarItemClickEvent={
          (componentGroupsName) => componentGroupsClickEvent(componentGroupsName)
        }
        links={
          dataContainer.getComponentByComponentGroupsId(activeComponentGroups.id)
            .sort((a,b) => a.order_no - b.order_no)
            .filter(i => i.name !== "Dashboard")
        }
        title={activeComponentGroups.name ?
          dataContainer.sectionKey(MainSettings.MAIN_CATEGORIES, activeComponentGroups.name.toLowerCase())
          : activeComponentGroups.name}
        onClickEvent={(componentName) => menuListClickEvent(componentName)}
      />
      <div className={classNames(
        "flex relative flex-col  justify-center flex-grow",
        {"items-center": activeComponentGroups.name !== "Interior"}
      )}>
        <div style={{ height: height ? `calc(${height * ((activeComponentGroups.name === "Interior") ? 0.71 : 0.58)}px)` : "100%"}}>
          {(imageView360 && activeComponentGroups.name === "Interior") ?
            <div className="w-full h-full object-cover relative flex justify-center items-center">
              <PanoramicViewROD/>
            </div>
            :
            (viewPoints?.length) &&
              <Slider/>
          }
        </div>
        <div className={classNames(
          {"flex w-full justify-center items-center pt-5 z-20": activeComponentGroups.name !== "Interior"},
          {"flex flex-row w-full justify-center bottom-4 absolute z-20 items-center pt-5": activeComponentGroups.name === "Interior"}
        )}>
          {activeComponentGroups.name === "Interior" && 
            <div className="absolute left-1 sm:left-5">
              <Button
                variant={VarianButtonEnum.ICONBUTTON}
                icon={imageView360 ? IconModelEnum.RealImage360 : IconModelEnum.StaticInteriorImage}
                customClassNames="!bg-light-grey-light w-10 h-10"
                onClickEvent={() => setImageView360(!imageView360)}
              />
            </div>
          }
          <div className="flex self-start">
            <Button
              variant={VarianButtonEnum.BACKBUTTON}
              text={dataContainer.sectionKey(MainButtons.MAIN_BUTTONS, MainButtons.BACK_BUTTON)}
              customClassNames="border border-blue"
              textTransform={true}
              onClickEvent={() => {
                navigateThroughComponents(
                  activeComponentGroups.index >= 0,
                  activeComponentGroups.index === 0 &&
                    dataContainer.getComponentByComponentGroupsId(activeComponentGroups.id)
                      .slice(0)[0].id === activeComponent.group_id,
                  "decrementComponent"
                );
              }}
            />
            <Button
              customClassNames="ml-6"
              variant={VarianButtonEnum.NEXTBUTTON}
              text={dataContainer.sectionKey(MainButtons.MAIN_BUTTONS, MainButtons.NEXT_BUTTON)}
              textTransform={true}
              onClickEvent={() => {
                navigateThroughComponents(
                  activeComponentGroups.index <= component_groups.length -1,
                  activeComponent.name === "Seats",
                  "incrementComponent"
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className={classNames(
        {"pt-5" : activeComponentGroups.name !== "Interior"}
      )}>
        <VariantItem updateCurrentView={() => setRenderImage(!renderImage)} />
      </div>
    </div>
  );
};
